<template>
  <div class="create-card">
    <div class="create-card__modal">
      <div class="create-card__loader" v-if="loading">
        <Loader />
      </div>
      <div v-else class="create-card__container">
        <h4 class="create-card__title">
          {{ editMode ? "Редактировать карточку" : "Добавить карточку" }}
        </h4>
        <Widget
          type="input-title"
          unique="card-name"
          placeholder="Название карточки"
          :maxlength="70"
          :editValue="cardName"
        />
        <Widget
          type="text"
          :maxlength="500"
          placeholder="Описание"
          label="Цель"
          unique="card-final"
          :editValue="cardFinal"
        />
        <Widget
          type="text"
          :maxlength="70"
          placeholder="Описание"
          label="Подсказка"
          unique="card-prompt"
          :editValue="cardPrompt"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'card-responsible'"
          type="select-new"
          label="Ответственный"
          placeholder="Выберите ответственного"
          :multiple="false"
          :editValue="cardResponsible"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'card-assistant'"
          type="select-new"
          label="Заместитель"
          placeholder="Выберите заместителя"
          :multiple="false"
          :editValue="cardAssistant"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'card-users'"
          type="select-new"
          label="Участники"
          placeholder="Выберите участников"
          :multiple="true"
          :editValue="cardUsers"
        />
        <Widget
          :editValue="cardPublic"
          type="Switch"
          label="Ограничение доступа"
          unique="card-public"
        />
        <div class="create-card__buttons">
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit('showCreateChangeCardModal', null)"
          />
          <ModalButton :title="editMode ? 'Сохранить' : 'Добавить'" @handleButton="cardSave" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import Loader from "@/components/Widgets/Loader2.0/Loader";
import httpClient from "@/api/client/httpClient";
const Widget = () => import("@/components/Widgets/Widget");

export default {
  name: "ModalCreateChangeCard",
  mixins: [],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    editMode: {
      type: Boolean
    }
  },
  components: { Loader, ModalButton, Widget },
  data() {
    return {
      loading: false,
      cardName: "",
      cardFinal: "",
      cardPrompt: "",
      cardResponsible: [],
      cardAssistant: [],
      cardUsers: [],
      cardPublic: false
    };
  },
  beforeDestroy() {
    this.$root.$off("text:change_card-name");
    this.$root.$off("textarea:change_card-final");
    this.$root.$off("textarea:change_card-prompt");
    this.$root.$off("changeSelectValue:card-responsible");
    this.$root.$off("changeSelectValue:card-assistant");
    this.$root.$off("changeSelectValue:card-users");
    this.$root.$off("switch:change_card-public");
    this.$root.$off("button:click_cancel-modal");
    this.$root.$off("button:click_save-button");
  },
  mounted() {
    this.$root.$on("text:change_card-name", (value) => {
      this.cardName = value;
    });
    this.$root.$on("textarea:change_card-final", (value) => {
      this.cardFinal = value;
    });
    this.$root.$on("textarea:change_card-prompt", (value) => {
      this.cardPrompt = value;
    });
    this.$root.$on("changeSelectValue:card-responsible", (value) => {
      this.cardResponsible = value;
    });
    this.$root.$on("changeSelectValue:card-assistant", (value) => {
      this.cardAssistant = value;
    });
    this.$root.$on("changeSelectValue:card-users", (value) => {
      this.cardUsers = value;
    });
    this.$root.$on("switch:change_card-public", (value) => {
      this.cardPublic = value;
    });
    if (this.editMode) {
      this.getMembersUsers();
      this.saveEditData();
    }
  },
  methods: {
    // Создание задачи
    cardSave() {
      if (!this.checkValidation()) return false;
      httpClient({
        url: `/OrgStructureItem/${this.editMode ? "actionUpdate" : "actionCreate"}${
          this.editMode ? `/${this.data.id}` : ""
        }`,
        method: this.editMode ? "PUT" : "POST",
        data: [
          {
            name: this.cardName.trim(),
            final_product: this.cardFinal.trim(),
            prompt: this.cardPrompt.trim(),
            responsible_user_id: this.cardResponsible.length
              ? this.cardResponsible.map((item) => item.id)[0]
              : null,
            assistant_user_id: this.cardAssistant.length
              ? this.cardAssistant.map((item) => item.id)[0]
              : null,
            parent_item_id: !this.editMode ? this.data.id : undefined,
            users: [
              ...new Set(
                this.cardUsers
                  .map((item) => item.id)
                  .concat(this.cardResponsible.map((item) => item.id))
                  .concat(this.cardAssistant.map((item) => item.id))
              )
            ],
            is_public: !this.cardPublic
          }
        ]
      })
        .then(() => {
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 3000,
            label: this.editMode ? "Карточка успешно изменена." : "Карточка успешно добавлена."
          });
          this.$root.$emit("showCreateChangeCardModal", null);
          this.$emit("updateStructure");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    saveEditData() {
      this.cardName = this.data.name;
      this.cardFinal = this.data.final_product;
      this.cardPrompt = this.data.prompt;
      this.cardResponsible = this.data.responsible_user ? [this.data.responsible_user] : [];
      this.cardAssistant = this.data.assistant_user ? [this.data.assistant_user] : [];
      this.cardPublic = !this.data.is_public;
    },
    // Проверка валидации
    checkValidation() {
      if (!this.cardName.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Введите название карточки!"
        });
        return false;
      }
      if (this.cardName.trim().length < 2 || this.cardName.length > 70) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Название должно быть не меньше 2 и не более 70 символов!"
        });
        return false;
      }
      if (!this.cardFinal.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Введите описание Цели!"
        });
        return false;
      }
      if (this.cardFinal.trim().length < 6 || this.cardFinal.length > 500) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Описание Цели должно быть не меньше 6 и не более 500 символов!"
        });
        return false;
      }
      if (this.cardPrompt && (this.cardPrompt.trim().length < 2 || this.cardPrompt.length > 70)) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 3000,
          label: "Описание Подсказки должно быть не меньше 2 и не более 70 символов!"
        });
        return false;
      }
      return true;
    },
    getMembersUsers() {
      this.loading = true;
      httpClient({
        url: `/User/getItems`,
        method: "GET",
        params: {
          _count: 300,
          org_structure_items: JSON.stringify([{ "org_structure_items.id": this.data.id }]),
          _with: JSON.stringify(["org_structure_items", "Roles"])
        }
      })
        .then((response) => {
          this.cardUsers = response.data.data.items;
          this.loading = false;
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.create-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: $background-modal;

  &__modal {
    position: relative;
    display: flex;
    min-width: 490px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 20px 20px;
    background: $surface-default;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.1), 0 10px 16px rgba(20, 37, 63, 0.02);
    border-radius: 24px;

    @media screen and (max-width: 540px) {
      min-width: 310px;
    }
    .lds-ellipsis {
      position: absolute;
      top: 47%;
      left: 44%;
      height: 0;
      width: 0;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50vh;
    max-height: 80vh;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-group-input-text {
      margin-bottom: 10px;

      /deep/ {
        .form-group__label {
          font-size: 12px;
        }
      }
    }

    .form-group-textarea {
      margin-bottom: 10px;

      /deep/ {
        .form-group__label {
          font-size: 12px;
        }

        textarea {
          height: 70px;
        }
      }
    }

    .select-bg {
      width: 100%;
      margin-bottom: 10px;

      /deep/ {
        .select-label {
          font-size: 12px;
        }

        .select-content-bg {
          height: 34px;
        }
        .modal-items {
          max-height: 100px;
        }
      }
    }
  }

  &__title {
    position: sticky;
    top: 0;
    padding: 20px 0 0;
    margin: 0 0 25px 0;
    background-color: #fff;
    font-size: 18px;
    line-height: 20px;
    color: $color-text-accent;
    font-weight: 400;
    z-index: 100;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/ {
    .select-bg .select-content-bg .select-content .select-content-value-bg .select-content-value {
      min-width: 97%;
    }
  }
  /deep/ {
    .switch-widget {
      margin-right: 24px;

      .switch-widget__container {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        margin-bottom: 10px;

        .switch-widget__label {
          font-size: 12px;
          line-height: 20px;
          color: $color-text-secondary;
        }
      }
    }
  }
}
</style>
